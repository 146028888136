layout {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    min-width: 0;

    /* Base styles for individual layouts */
    > * {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
    }

    /* Base styles for components that load as a route */
    router-outlet {

        + * {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            width: 100%;
        }
    }
}

.authentication-pages-layout{
  @apply flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0;
}

.authentication-pages-container{
  @apply md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card;
}

.banner-widget-container{
  @apply h-full w-1/2 hidden md:flex;
}

